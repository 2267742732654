import { IQuizContext } from './QuizContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FormValues } from '@wix/form-viewer/widget';
import { initFormController } from '@wix/form-viewer/controller';
import {
  createSubmission,
  getSubmission,
} from '@wix/ambassador-forms-v4-submission/http';
import { SubmissionStatus } from '@wix/ambassador-forms-v4-submission/types';

export const quizPropsMap = (flowAPI: ControllerFlowAPI): IQuizContext => {
  return {
    controllerIsReady: false,
    initController: async (formId: string) => {
      flowAPI.controllerConfig.setProps({
        controllerIsReady: false,
        lastSubmissionId: null,
      });

      await initFormController(flowAPI, {
        formId,
      });

      flowAPI.controllerConfig.setProps({
        controllerIsReady: true,
      });
    },
    sendSubmission: async (formId: string, values: FormValues) => {
      const sbmsn = await flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: values,
            status: SubmissionStatus.PENDING,
          },
        }),
      );

      flowAPI.controllerConfig.setProps({
        lastSubmissionId: sbmsn?.data?.submission?.id,
        submission: sbmsn?.data?.submission,
      });
    },
    lastSubmissionId: null,
    getSubmission: async (submissionId: string) => {
      const sbmsn = (
        await flowAPI.httpClient.request(
          getSubmission({
            submissionId,
          }),
        )
      )?.data;

      flowAPI.controllerConfig.setProps({
        submission: sbmsn?.submission,
      });
    },
    submission: null,
  };
};
